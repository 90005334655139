<template>
    <v-card flat>
        <v-card-text>
            <v-card-title>
                <div class="d-flex flex-column">
                    <div class="d-flex align-center">
                        <span class="secondary--text font-weight-bold">Assignments</span>
                    </div>
                </div>
                <v-spacer></v-spacer>
                <v-btn v-if="!assignmentsExist && isSpaceAdmin" @click="startAssignmentTour" text color="secondary">
                    <v-icon small>add</v-icon>
                    <span class="font-weight-bold">Create a new assignment</span>
                </v-btn>
            </v-card-title>
            <div v-if="filteredBundlesByType.length && !fetchingSpaceBundles">
                <div class="d-flex align-center">
                    <v-spacer></v-spacer>
                    <v-text-field
                        prepend-inner-icon="mdi-filter"
                        autofocus
                        hide-details
                        solo
                        flat
                        background-color="grey lighten-4"
                        dense
                        label="Filter assignments..."
                        v-model="search"
                        clearable
                        class="mr-2"></v-text-field>
                    <v-btn :loading="fetchingSpaceBundles" @click="$store.dispatch('spaceStore/fetchSpaceBundles', $route.params.sid)" icon>
                        <v-icon>refresh</v-icon>
                    </v-btn>
                </div>
                <v-data-table
                    :search="search"
                    item-key="bid"
                    :options="tableOptions"
                    :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
                    :headers="headers"
                    v-model="selected"
                    :items="filteredBundlesByType">
                    <template v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :itemsPerPageOptions="itemsPerPageOptions"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            class="no-border" />
                    </template>
                    <template v-slot:[`item.long_id`]="{ item }">
                        <div class="py-2">
                            <v-hover v-slot:default="{ hover }">
                                <div
                                    :style="hover ? 'cursor: pointer;' : ''"
                                    :class="[hover ? 'secondary--text' : 'secondary--text', 'subtitle-1', 'font-weight-bold', 'text-decoration-none']"
                                    @click="gotoAssignment(item.bid)">
                                    {{ item.long_id }}
                                </div>
                            </v-hover>
                            <div class="subtitle-2" v-if="item.description">{{ item.description }}</div>
                        </div>
                    </template>
                    <template v-slot:[`item.assignment_status`]="{ item }">
                        <v-chip small>{{ item.assignment_status }}</v-chip>
                    </template>
                    <template v-slot:[`item.submission_status`]="{ item }">
                        <v-chip small v-if="item.submission_status === 'No submissions'" color="orange">
                            {{ item.submission_status }}
                        </v-chip>
                        <v-chip v-else small color="success">{{ item.submission_status }}</v-chip>
                    </template>
                    <template v-slot:[`item.allow_handins_until`]="{ item }">
                        <div class="d-flex align-center">
                            <span class="subtitle-2" :class="handinDeadlineStyle(item.allow_handins_until).color">
                                {{ item.allow_handins_until | dateTimeToHuman }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex justify-beginning">
                            <TheSnapshotAssignmentSubmitDialog
                                v-if="!isSpaceAdmin && !isAssignmentOverdue(item.allow_handins_until) && !isMasterInstance"
                                :bundleData="item" />
                            <BundleDeleteDialog v-if="isSpaceAdmin" :bundleData="item" />
                            <BundleEditDialog v-if="isSpaceAdmin" :bundleData="item" />
                        </div>
                    </template>
                </v-data-table>
            </div>
            <div v-else-if="!filteredBundlesByType.length && !fetchingSpaceBundles">
                <v-alert text prominent type="info">
                    <div class="d-flex align-center justify-space-between w-100">
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">No assignments found</span>
                            <span>
                                So far no assignments have been created.
                                <span v-if="isSpaceAdmin">
                                    You can create new assignments by staging and sharing objects (files, tables, and applications) as an assignment.
                                </span>
                            </span>
                        </div>
                        <v-btn
                            v-if="isSpaceAdmin"
                            href="https://docs.nuvolos.cloud/user-guides/education-guides/setting-assignments"
                            target="_blank"
                            outlined
                            small
                            color="info">
                            Learn more
                        </v-btn>
                    </div>
                </v-alert>
            </div>
            <div v-else-if="fetchingSpaceBundles">
                <div
                    v-for="item in [
                        { id: 'c1', opacity: 1 },
                        { id: 'c2', opacity: 0.75 },
                        { id: 'c3', opacity: 0.5 }
                    ]"
                    :key="item.id"
                    class="my-6">
                    <div :style="{ opacity: item.opacity }">
                        <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { assignmentMethods } from '@/mixins/assignments'
import { assignmentTour } from '@/mixins/assignmentTour'
import { sortDateArray, calculateDateDifferenceInDays } from '@/utils'

const BundleDeleteDialog = () => import('../components/TheSnapshotBundleDeleteDialog')
const BundleEditDialog = () => import('../components/TheSnapshotBundleEditDialog')
const TheSnapshotAssignmentSubmitDialog = () => import('../components/TheSnapshotAssignmentSubmitDialog')

export default {
    components: {
        BundleDeleteDialog,
        TheSnapshotAssignmentSubmitDialog,
        BundleEditDialog
    },
    mixins: [assignmentMethods, assignmentTour],
    data() {
        return {
            distributionReasons: {
                MATERIAL_SHARING: 'DISTRIBUTION',
                ASSIGNMENT: 'ASSIGNMENT'
            },
            headers: [
                { text: 'Name / Description', value: 'long_id', align: 'left' },
                {
                    text: 'Assignment status',
                    value: 'assignment_status',
                    align: 'left'
                },
                { text: 'Submission status', value: 'submission_status', align: 'left' },
                { text: 'Hand-in deadline', value: 'allow_handins_until', align: 'left' },
                { text: 'Actions', value: 'actions' }
            ],
            selected: [],
            search: '',
            itemsPerPageOptions: [25, 50, 100, -1],
            tableOptions: {
                page: 0,
                itemsPerPage: 25,
                pageStart: 1,
                pageStop: 25,
                itemsLength: 25
            }
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceBundles', 'fetchingSpaceBundles']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'assignmentsExist']),
        ...mapGetters('instanceStore', ['isMasterInstance']),
        filteredBundlesByType() {
            const filteredBundles = this.spaceBundles.filter(bundle => bundle.bundle_type_name === this.distributionReasons.ASSIGNMENT)
            filteredBundles.forEach(bundle => {
                if (!this.isAssignmentOverdue(bundle.allow_handins_until)) {
                    bundle.assignment_status = 'Open'
                } else if (this.isAssignmentOverdue(bundle.allow_handins_until) && !bundle.handbacks_visible) {
                    bundle.assignment_status = 'Grading'
                } else {
                    bundle.assignment_status = 'Grades visible'
                }
                if (bundle.handins && bundle.handins.length > 0) {
                    bundle.submission_status = `${this.gradeableHandinsSum(bundle)} gradeable submission(s)`
                } else {
                    bundle.submission_status = 'No submissions'
                }
            })
            return sortDateArray(filteredBundles, 'allow_handins_until', 'descending')
        }
    },
    methods: {
        handinDeadlineStyle(deadline) {
            const dayDiff = calculateDateDifferenceInDays(new Date(), deadline)
            if (dayDiff > 7) {
                return { color: 'green--text text--lighten-1' }
            } else if (dayDiff > 1) {
                return { color: 'orange--text text--darken-1' }
            } else if (dayDiff > 0) {
                return { color: 'orange--text text--darken-1' }
            } else if (dayDiff === 0) {
                return { color: 'red--text text--darken-2' }
            } else {
                return { color: 'secondary--text' }
            }
        },
        gradeableHandinsSum(assignment) {
            return assignment.handins.filter(handin => handin.is_latest).length
        }
    }
}
</script>
